import React, { createContext, useContext, useState } from 'react'
import Swal from 'sweetalert2'
import env from "react-dotenv";
import { toast } from "react-toastify"
import { GlobalContext } from './GlobalStateContext';

const TicketContext = createContext('');

const TicketProvider = ({ children, props }) => {

  const { setIsLoading, encodePetition, accessToken } = useContext(GlobalContext)
  const [montoForm, setMontoForm] = useState('')
  const [detalle, setDetalle] = useState([])
  const [detalleTicketArticulos, setDetalleTicketArticulos] = useState([])
  const [tipoForm, setTipoForm] = useState('')
  const [bancoForm, setBancoForm] = useState('')
  const [calcularAction, setCalcularAction] = useState(false)
  const [propietarioFilter, setPropietarioFilter] = useState([])
  const [propietarioFilter2, setPropietarioFilter2] = useState([])
  const [checkServicio, setCheckServicio] = useState(false)
  const [checkProrrateo, setCheckProrrateo] = useState(false)
  const [activeDetalleModal, setActiveDetalleModal] = useState(false)
  const [activeDetalleArticuloModal, setActiveDetalleArticuloModal] = useState(false)
  const [activeEditarModal, setActiveEditarModal] = useState(false)
  const [preArticles, setPreArticles] = useState([])
  const [fechaFilter, setFechaFilter] = useState(null); 
  const [tipoClienteFilter, setTipoClienteFilter] = useState('')
  const [nombresFilter, setNombresFilter] = useState('')
  const [desdeFilter, setDesdeFilter] = useState(new Date(Date.now()).toISOString().slice(0,8) +'01')
  const [hastaFilter, setHastaFilter] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0,10))
  const [activeFilters, setActiveFilters] = useState([])
  const [filteredArticles, setFilteredArticles] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [ids, setIds] = useState([])
  const [detalleTickets, setDetalleTickets] = useState([])
  const [propietariosList, setPropietariosList] = useState([])
  const [propietarios, setPropietarios] = useState([])
  const [activePdfViewer, setActivePdfViewer] = useState(false)
  const [typePdf, setTypePdf] = useState('comprobante')
  const [tickets, setTickets] = useState([])
  const [ticket, setTicket] = useState({
    "idTicket": "",
    "label": "",
    "mes": "",
    "anio": "",
    "periodo": "",
    "monto": "",
    "idPropietario": "",
    "tipoPago": "",
    "tipoComprobante": "",
    "numero": "",
    "link": "",
    "estado": "",
    "fecha": "",
    "niubiz": "",
    "Nombre": "",
    "Apellido": "",
    "propietario": ""
  })

  const setTicketDefault = () => {
    setTicket({
      "Apellido": "",
      "Nombre": "",
      "anio": "",
      "estado": "",
      "fecha": "",
      "fecha_fin": "",
      "filename": "",
      "idPropietario": "",
      "idTicket": "",
      "idTramo": "",
      "label": "",
      "link": "",
      "mes": "",
      "monto": "",
      "montoOriginal": "",
      "niubiz": "",
      "numero": "",
      "periodo": "",
      "propietario": "",
      "tipoComprobante": "",
      "tipoPago": ""      
    })
  }

  const resetPago = () => {
    setMontoForm('')
    setTipoForm('')
    setBancoForm('')
  }


  const deleteTicket = (id) => {
    setIsLoading(true);
    fetch(env.API_URL+"/ticket/"+id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        getTickets();
        Swal.fire({
          title: 'Listo',
          text: 'El ticket fue eliminado',
          icon: 'success'
        })
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const getTickets = () => {
    setIsLoading(true);
    let details = { tipoClienteFilter, nombresFilter, desdeFilter, hastaFilter }
    fetch(env.API_URL+"/ticket", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        setTickets(res)
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const updateTicket = () => {
    setIsLoading(true);
    let details = Object.assign({}, ticket)
    fetch(env.API_URL+"/ticket/"+ticket.idTicket, {
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        document.body.style.overflowY = "visible"
        setActiveEditarModal(false)
        uploadFile();
        setTicketDefault()
        getTickets()
        toast("Ticket actualizado")
      })
      .catch((error) =>{
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const uploadFile = () => {
    setIsLoading(true);
    if(!selectedFile) {
      setIsLoading(false)
      return;
    }
    let formData = new FormData();
    formData.append("file", selectedFile);
    fetch(env.API_URL+"/ticket/"+ticket.idTicket+"/upload", {
      method: "POST",
      headers: {
        "Authorization": "Bearer "+accessToken
      },
      body: formData
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        document.body.style.overflowY = "visible"
        setSelectedFile()
        getTickets()
        toast("Archivo cargado")
      })
      .catch((error) =>{
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const getDetalleTicket = (ticketHeader) => {
    setTicket(ticketHeader)
    setIsLoading(true);
    fetch(env.API_URL+"/ticket/"+ticketHeader.idTicket, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        setDetalle(res)
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const getDetalleTicketArticulos = (idDetalleTicket) => {
    setIsLoading(true);
    fetch(env.API_URL+"/detalle-ticket/"+idDetalleTicket, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        setDetalleTicketArticulos(res)
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const getTicketsPropietario = () => {
    resetPago();
    setCalcularAction(false)
    setIsLoading(true);
    fetch(env.API_URL+"/ticket/propietario/"+propietarioFilter+'/due', {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        setDetalleTickets(res)
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const listarPropietarioConTickets = () => {
    setIsLoading(true);
    fetch(env.API_URL+"/ticket/propietario/due", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        setPropietariosList(res)
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const getPropietario = () => {
    setIsLoading(true);
    fetch(env.API_URL+"/propietario", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        setPropietarios(res)
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const getArticuloPorPropietario = (id, fecha) => {
    setIsLoading(true);
    fetch(env.API_URL+"/propietario/"+id+"/articulo/fecha/"+fecha, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setIds([])
        setPreArticles([])
        setTimeout(() => {
          setPreArticles(res)
          setIsLoading(false)
        }, 300);
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
        setIsLoading(false)
      })
  }
    
  const generarTickets = () => {
    if(!fechaFilter) {
      return;
    }
    if(propietarioFilter2.length === 1) {
      if(!ids) {
        return;
      }    
    }
    setIsLoading(true);
    let idsAux = '';
    let fecha = fechaFilter.toJSON().slice(0,10);
    let owner = '';
    if(propietarioFilter2.length) {
      owner = propietarioFilter2.map(item=> "'"+item.split("-")[0]+"'").join(",");
    }
    if(ids.length) {
      idsAux = ids.map(item=> "'"+item+"'").join(",");
    }
    let details = {  ids:idsAux, owner, fecha, servicio: checkServicio, prorrateo: checkProrrateo }
    fetch(env.API_URL+"/ticket/article", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
        body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        setFechaFilter(null)
        setPropietarioFilter2([])
        setPreArticles([])
        setIds([])
        setCheckServicio(false)
        setCheckProrrateo(false)
        if(res.code === 200) {
          toast("Tickets generados")
        } else {
          Swal.fire({
            title: 'No autorizado',
            text: 'No tiene permiso para realizar esta operacion',
            icon: 'warning'
          })
        }
      })
      .catch((error) =>{
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }
  
  const guardarPago = () => {
    if(!montoForm || !tipoForm || !bancoForm) {
      Swal.fire({
        title: 'Advertencia',
        text: 'Complete los campos',
        icon: 'warning'
      })
      return;
    }
    if(!calcularAction) {
      Swal.fire({
        title: 'Advertencia',
        text: 'Debe calcular primero',
        icon: 'warning'
      })
      return;
    }
    setIsLoading(true);
    let data = {
      monto: montoForm,
      tipo: tipoForm,
      banco: bancoForm,
      idPropietario: propietarioFilter,
      detalleTickets: detalleTickets
    }
    fetch(env.API_URL+"/pago", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+accessToken
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((res) => {
        getTicketsPropietario()
        toast("Pago guardado")
      })
      .catch((error) =>{
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }
  
  const buildPdfTicket = (id) => {
    setIsLoading(true);
    fetch(env.API_URL+"/pdf/ticket/"+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
          setIsLoading(false)
          toast("Pdf generado")
      })
      .catch((error) =>{
        console.log(error);
        Swal.fire({
          title: 'No autorizado',
          text: 'No tiene permiso para realizar esta operacion',
          icon: 'warning'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const object = {
    tickets, setTickets, 
    ticket, setTicket, setTicketDefault,
    detalle, setDetalle,
    detalleTicketArticulos,
    tipoForm, bancoForm, montoForm,
    deleteTicket,
    getTickets,
    updateTicket,
    getDetalleTicket,
    getDetalleTicketArticulos,
    getTicketsPropietario,
    listarPropietarioConTickets,
    getPropietario,
    getArticuloPorPropietario,
    generarTickets,
    guardarPago,
    buildPdfTicket,
    preArticles,
    fechaFilter,
    setFechaFilter,
    tipoClienteFilter, setTipoClienteFilter,
    nombresFilter, setNombresFilter,
    desdeFilter, setDesdeFilter,
    hastaFilter, setHastaFilter,
    activeFilters, setActiveFilters,
    filteredArticles, setFilteredArticles,
    propietariosList,
    propietarios,
    activeDetalleModal, setActiveDetalleModal,
    activeDetalleArticuloModal, setActiveDetalleArticuloModal,
    propietarioFilter, setPropietarioFilter,
    propietarioFilter2, setPropietarioFilter2,
    detalleTickets, setDetalleTickets,
    setBancoForm,
    setTipoForm,
    setMontoForm,
    setCalcularAction,
    activeEditarModal, setActiveEditarModal,
    selectedFile, setSelectedFile,
    typePdf, setTypePdf,
    activePdfViewer, setActivePdfViewer,
    ids, setIds,
    setPreArticles,
    setCheckServicio,
    setCheckProrrateo
  };


  return (
      <TicketContext.Provider value={object}>{children}</TicketContext.Provider>
  );
};

export { TicketProvider };
export default TicketContext;
